:root {
    --theme-background-page: #F9F9FC;
    --theme-background-window: #8F959E52;
    --theme-text-default: #F9F9FC;
}

.icon.add { background: url("../images/add-white.svg"); }
.icon.card-edit { background: url("../images/card_edit.svg"); }
.icon.card-eye { background: url("../images/card_eye.svg"); }
.icon.card-lock { background: url("../images/card_lock.svg"); }
.icon.card-summary-order { background: url("../images/card_summary_order.svg"); }
.icon.card-summary-payment { background: url("../images/card_summary_payment.svg"); }
.icon.card-summary-person { background: url("../images/card_summary_person.svg"); }
.icon.card-trash { background: url("../images/card_trash.svg"); }
.icon.card-trash-2 { background: url("../images/card_trash_2.svg"); }
.icon.card-user-caret { background: url("../images/card_user_caret.svg"); }
.icon.cta-calendar { background: url("../images/cta_calendar.svg"); }
.icon.change-up { background: url("../images/change_up.svg"); }
.icon.export { background: url("../images/export_primary.svg"); }
.icon.filter { background: url("../images/filter.svg"); }
.icon.information { background: url("../images/information.svg"); }
.icon.logout { background: url("../images/logout.svg"); }
.icon.navigation-admin-users { background: url("../images/navigation_admin_users.svg"); }
.icon.navigation-attendees { background: url("../images/navigation_attendees.svg"); }
.icon.navigation-calendar { background: url("../images/navigation_calendar.svg"); }
.icon.navigation-caret { background: url("../images/navigation_caret.svg"); }
.icon.navigation-dashboard { background: url("../images/navigation_dashboard.svg"); }
.icon.navigation-events,
.icon.navigation-notifications { background: url("../images/user_notifications.svg"); }
.icon.navigation-transactions { background: url("../images/navigation_events_transactions.svg"); }
.icon.navigation-tickets { background: url("../images/navigation_tickets.svg"); }
.icon.navigation-vendors { background: url("../images/navigation_vendors.svg"); }
.icon.pagination-left { background: url("../images/pagination_left.svg"); }
.icon.pagination-right { background: url("../images/pagination_right.svg"); }
.icon.profile { background: url("../images/profile.svg"); }
.icon.search { background: url("../images/search.svg"); }
.icon.sign-in-lock { background: url("../images/sign_in_lock.svg"); }
.icon.sign-in-user-icon { background: url("../images/sign_in_user_icon.svg"); }
.icon.three-dots { background: url("../images/three_dots.svg"); }