[ data-page-name = "b_e_n_p" ] #content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    overflow-y: hidden;
}

[ data-page-name = "b_e_n_p" ] .data {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 2rem;
    overflow: hidden;
}
[ data-page-name = "b_e_n_p" ] .data > * {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto !important;
}
[ data-page-name = "b_e_n_p" ] .data > .list {
    flex-shrink: 0;
    gap: 0.75rem;
    width: var(--NAVIGATION-PANEL-WIDTH);
}
[ data-page-name = "b_e_n_p" ] .data > .list .card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
[ data-page-name = "b_e_n_p" ] .data > .list .card.selected {
    background: #E4E4E4;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
}
[ data-page-name = "b_e_n_p" ] .data > .list .card > .name {
    font-weight: 550;
}
[ data-page-name = "b_e_n_p" ] .data > .list .card > .others { font-size: 0.8rem; }



/* details pane */
[ data-page-name = "b_e_n_p" ] .details {
    flex-grow: 1;
    gap: 1.5rem;
}
[ data-page-name = "b_e_n_p" ] .details > .banner {
    aspect-ratio: 3/1;
    border-radius: 1rem;
    flex-shrink: 0;
    overflow: hidden;
    width: 100%;
}
[ data-page-name = "b_e_n_p" ] .details > .banner > * {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
[ data-page-name = "b_e_n_p" ] .details > .banner > .no-pic { font-size: 4rem; }
[ data-page-name = "b_e_n_p" ] .details > .ctas {
    align-items: center;
    display: flex;
    gap: 1rem;
}
[ data-page-name = "b_e_n_p" ] .details > .ctas > * {
    background: #8F959E;
    border-radius: 0.5rem;
    color: white;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
}
[ data-page-name = "b_e_n_p" ] .details > .brief {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
}
[ data-page-name = "b_e_n_p" ] .details > .brief > * {
    margin-right: 7.5%;
    max-width: 35%;
    min-width: 35%;
    width: 35%;
}
[ data-page-name = "b_e_n_p" ] .details > .gallery {
    align-items: center;
    display: flex;
    gap: 2rem;
    overflow-x: auto;
    white-space: nowrap;
}
[ data-page-name = "b_e_n_p" ] .details > .gallery > * {
    flex: 1 1 0px;
    max-width: 7.5rem;
    min-width: 7.5rem;
    width: 7.5rem;
}


/* details topic */
[ data-page-name = "b_e_n_p" ] .details .topic {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
[ data-page-name = "b_e_n_p" ] .details .topic > .label {
    color: #B6B6B6;
    font-size: 0.9rem;
}
[ data-page-name = "b_e_n_p" ] .details .topic > .value {
    color: #3E3E3E;
    font-weight: 550;
}
