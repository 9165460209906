/* a_n_V */
.card.anv,
.card.order {
    align-items: center;
    border-bottom: 0.0625rem solid #E9E7FD;
    display: flex;
    gap: 2.5rem;
    padding: 1.25rem 0.5rem;
}
.card.anv:not(.header),
.card.order:not(.header) { background: white; }

.card.anv > .selector,
.card.order > .selector {
    border: 0.0625rem solid #858D9D;
    border-radius: 0.35rem;
    height: 1.5rem;
    max-width: 1.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
}
.card.anv.selected > .selector
.card.order.selected > .selector { background: #721109; }

.card.anv.header > :not(.selector),
.card.order.header > :not(.selector) {
    align-items: center;
    display: flex;
}

.card.anv > .object,
.card.order > .object {
    display: flex;
    gap: 0.75rem;
}
.card.anv > .object > .image,
.card.order > .object > .image {
    border-radius: 5rem;
    height: 2.5rem;
    overflow: hidden;
    width: 2.5rem;
}
.card.anv > .object > .image > img,
.card.order > .object > .image > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.card.anv > .object > .brief,
.card.order > .object > .brief {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card.anv > .object > .brief > .main,
.card.order > .object > .brief > .main { font-weight: 550; }
.card.anv > .object > .brief > .sub,
.card.order > .object > .brief > .sub { color: #8B909A; }
.card.anv > .actions,
.card.order > .actions {
    align-items: center;
    display: flex;
    gap: 1rem;
}
.card.anv > .actions > .icon,
.card.order > .actions > .icon {
    height: 1.5rem;
    width: 1.5rem;
}

.card.anv:not(.header) { color: #721109; }
.card.anv > :not(.selector) { flex: 1 1 0px; }
.card.anv > .actions {
    max-width: 6.5rem;
    min-width: 6.5rem;
    width: 6.5rem;
}
.card.anv > .object {
    max-width: calc(100% - 52rem);
    min-width: calc(100% - 52rem);
    width: calc(100% - 52rem);
}
.card.anv > .phone,
.card.anv > .date-created {
    max-width: 17rem;
    min-width: 17rem;
    width: 17rem;
}

.card.order > * { flex-shrink: 0; }
.card.order > .id,
.card.order > .date-created {
    max-width: 10rem;
    min-width: 10rem;
    width: 10rem;
}
.card.order > .object {
    max-width: calc((100% - 63.5rem) / 2);
    min-width: calc((100% - 63.5rem) / 2);
    width: calc((100% - 63.5rem) / 2);
}
.card.order > .status,
.card.order > .payment,
.card.order > .total {
    max-width: 6rem;
    min-width: 6rem;
    width: 6rem;
}
.card.order > .actions {
    max-width: 4rem;
    min-width: 4rem;
    width: 4rem;
}
.card.order:not(.header) { color: #667085; }
.card.order:not(.header) > .total { color: #721109; }
.card.order:not(.header) > .status {
    border-radius: 0.5rem;
    padding: 0.2rem 0.3rem;
    text-align: center;
}
.card.order:not(.header) > .status.cancelled {
    background: #FEECEE;
    color: #EB3D4D;
}
.card.order:not(.header) > .status.delivered {
    background: #E9FAF7;
    color: #1A9882;
}
.card.order:not(.header) > .status.processing { 
    background: #FFF0EA;
    color: #F86624;
}
.card.order:not(.header) > .status.shiped {
    background: #EAF8FF;
    color: #2BB2FE;
}



/* b_e_n_p */
.card.benp {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
}
.card.benp.selected {
    background: wheat;
    padding: 0.5rem 0.75rem;
}
.card.benp > .others {
    align-items: center;
    color: #61758A;
    display: flex;
    gap: 0.5rem;
}



/* chart */
.card.chart {
    border: 0.0625rem solid #E0E2E7;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: calc(45rem + ((100% - 90rem) / 3));
}
.card.chart > .description {
    align-items: center;
    display: flex;
    gap: 8.25rem;
    position: relative;
}
.card.chart > .description > .id {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}
.card.chart > .description > .id > .main {
    font-size: 1.5rem;
    font-weight: 650;
}
.card.chart > .description > .id > .sub {
    color: #777980;
    font-size: 0.95rem;
    justify-self: center;
}
.card.chart > .description > .legend {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.95rem;
    gap: 2rem;
}
.card.chart > .description > .legend > * {
    padding-left: 1.5em;
    position: relative;
}
.card.chart > .description > .legend > *::before {
    border-radius: 1rem;
    content: "";
    height: 1em;
    left: 0;
    position: absolute;
    top: 0;
    width: 1em;
}
.card.chart > .description > .legend > .c1::before { background: linear-gradient(180deg, #3250FF 0%, #2BB2FE 100%); }
.card.chart > .description > .legend > .c2::before { background: linear-gradient(180deg, #F86624 0%, #F9C80E 100%); }
.card.chart > .description > .options {
    position: absolute;
    right: 0;
    top: 0;
}
.card.chart > .description > .options > .content {
    border-radius: 0.5rem;
    display: none;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: calc(100% + 1rem);
    width: 7.5rem;
}
.card.chart > .description > .options.active > .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}



/* notification */
.card.notification,
.card.notification > .indi {
    align-items: flex-start;
    display: flex;
    gap: 0.5rem;
}
.card.notification {
    align-items: flex-start;
    padding: 1.5rem 1rem;
}
.card.notification.active { background: #0061FE14; }
.card.notification > .indi { align-items: center; }
.card.notification > .indi > .indicator {
    border-radius: 3rem;
    height: 0.75rem;
    width: 0.75rem;
}
.card.notification.unread > .indi > .indicator { background: #9B0032; }
.card.notification > .indi > .image {
    border-radius: 3rem;
    height: 3.5rem;
    margin-right: 1rem;
    overflow: hidden;
    width: 3.5rem;
}
.card.notification > .details {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    gap: 1rem;
}
.card.notification.unread > .details { font-weight: 550; }
.card.notification > .details > .title {
    all: unset;
    color: #1E1919;
    font-size: 1.125rem;
}
.card.notification > .details > .attachments,
.card.notification > .details > .attachments > .attachment {
    align-items: center;
    display: flex;
    font-size: 0.9rem;
    font-weight: initial;
    gap: 1rem;
}
.card.notification > .details > .attachments { flex-wrap: wrap; }
.card.notification > .details > .attachments > .attachment {
    border: 0.0625rem solid #63728229;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
}
.card.notification > .details > .datetime { color: #524A3ED1; }



/* summary */
.card.summary {
    border: 0.0625rem solid #E0E2E7;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    position: relative;
    width: 22.5rem;
}
.card.summary > .banner {
    border-radius: 10rem;
    height: 3.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3.5rem;
}
.card.summary > .banner > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.card.summary > .main {
    color: #721109;
    font-size: 2.25rem;
    font-weight: 750;
}
.card.summary > .label,
.card.summary > .change {
    align-items: center;
    display: flex;
    gap: 1rem;
}
.card.summary > .label {
    color: #777980;
    font-size: 1.05rem;
    font-weight: 550;
}
.card.summary > .change { color: #858D9D; }
.card.summary > .change > .percentage {
    align-items: center;
    display: flex;
    font-weight: 550;
    gap: 0.5rem;
}
.card.summary > .change > .percentage.down { color: #EB3D4D; }
.card.summary > .change > .percentage.up { color: #1A9882; }



/* user */
.card.user {
    align-items: center;
    display: flex;
    gap: 1rem;
    width: fit-content;
}
.card.user > .picture {
    background: #701C15;
    border-radius: 5rem;
    height: 2.5rem;
    position: relative;
    width: 2.5rem;
}
.card.user > .picture > img {
    border-radius: 5rem;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.card.user > .picture > .no-pic {
    border-radius: 5rem;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
}
.card.user > .picture > .cta {
    background: #701C15;
    border: 0.18rem solid ghostwhite;
    border-radius: 1rem;
    bottom: 0;
    height: 0.9rem;
    position: absolute;
    right: 0;
    width: 0.9rem;
}
.card.user > .description {
    display: flex;
    flex-direction: column;
    font-weight: 550;
    height: 2.5rem;
    justify-content: space-between;
}
.card.user > .description > .name { font-size: 1.0rem; }
.card.user > .description > .role {
    color: #701C15;
    font-size: 0.7rem;
}
.card.user > .options { position: relative; }
.card.user > .options.drop > :not(.content) { transform: rotateX(180deg); }
.card.user > .options > .content {
    background: #FFFFFF;
    border-radius: 0.5rem;
    display: none;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: calc(100% + 2.5rem);
    width: max-content;
}
.card.user > .options.drop > .content {
    display: flex;
    flex-direction: column;
    font-weight: 550;
    gap: 1.5rem;
}
.card.user > .options > .content > * {
    align-items: center;
    display: flex;
    gap: 1rem;
    white-space: nowrap;
}