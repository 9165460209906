body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* { box-sizing: border-box; }

.icon {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  height: 1.1em;
  width: 1.1em;
}

.date-ranges {
  align-items: center;
  border: 0.0625rem solid #E0E2E7;
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  padding: 0.25rem;
  width: fit-content;
}
.date-ranges > * {
  border-radius: 0.5rem;
  color: #667085;
  padding: 0.5rem 0.75rem;
}
.date-ranges > .active {
  background: #F0F1F3;
  color: #721109;
}

.filter-bar {
  align-items: center;
  display: flex;
  gap: 2.5rem;
  justify-content: space-between;
  padding: 0.25rem;
}
.filter-bar > * > * {
  border-radius: 0.25rem;
  padding: 1rem;
}
.filter-bar > * > .active {
  background: #F0F1F3;
  color: #721109;
}

.no-pic {
  align-items: center;
  background: #721109;
  color: #FFFFFF;
  display: flex;
  font-weight: 750;
  height: 100%;
  justify-content: center;
  width: 100%;
}