.navigation {
    background: var(--THEME-NAV-BACKGROUND);
    font-size: 0.875rem;
}
.navigation a {
    color: var(--text-default);
    text-decoration: none;
}



/* filter bar */
#filter-bar,
#filter-bar > .ctas,
#filter-bar > .ctas > * {
    align-items: center;
    display: flex;
}
#filter-bar { justify-content: space-between; }
#filter-bar > .ctas { gap: 1rem; }
#filter-bar > .ctas > * {
    border: 0.0625rem solid #E0E2E7;
    border-radius: 0.5rem;
    color: #667085;
    gap: 1rem;
    padding: 0.5rem 1rem;
}
#filter-bar > .search {
    border: 0.0625rem solid #E0E2E7;
    border-radius: 0.5rem;
}
#filter-bar > .search form { padding: 0; }
#filter-bar > .search button { background: transparent; }
#filter-bar > .search .fieldset {
    align-items: center;
    display: flex;
}
#filter-bar > .search .keyarea {
    border: none !important;
    width: 20rem;
}



/* pagination */
#pagination,
#pagination > .controls,
#pagination > .controls > .keys,
#pagination > .controls > .keys > * {
    align-items: center;
    display: flex;
}
#pagination {
    justify-content: space-between;
    padding-block: 1.5rem;
}
#pagination.min {
    align-items: initial;
    flex-direction: column;
    gap: 1rem;
}
#pagination > .range { color: #667085; }
#pagination > .controls,
#pagination > .controls > .keys { gap: 1rem; }
#pagination > .controls { color: #721109; }
#pagination.min > .controls {
    gap: initial;
    justify-content: space-between;
}
#pagination > .controls > :not(.keys),
#pagination > .controls > .keys > * {
    border: 0.0625rem solid #E0E2E7;
    border-radius: 0.5rem;
    height: 2rem;
    justify-content: center;
    width: 2rem;
}
#pagination > .controls > :not(.keys) { background-size: 1.1rem !important; }
#pagination > .controls > .keys > .active {
    background: #721109;
    color: #FFFFFF;
}



/* panel */
#panel {
    border-right: 0.0625rem solid #F0F1F3;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    height: 100%;
}
#panel * { cursor: default; }

#panel > .head {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
#panel > .head > * {
    cursor: pointer;
    height: 1.5em;
}
#panel > .head > .back img { height: 100%; }

#panel > .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 5rem;
    overflow-y: auto;
}
#panel > .body > .links {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
}
#panel > .body > .links .link {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
}

#panel > .body > .links .link.active {
    background: #F9F9FC;
    position: relative;
}
#panel > .body > .links .link.active::after {
    background: linear-gradient(180deg, #3250FF 0%, #2BB2FE 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0.375rem;
}

#panel > .body > .links > .link,
#panel > .body > .links > .link-combo > .dom > .link { padding: 0.75rem 1.5rem; }

#panel > .body > .links > .link-combo > .dom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}
#panel > .body > .links > .link-combo > .dom > .link { flex-grow: 1; }
#panel > .body > .links > .link-combo > .dom > .controller {
    background-size: 1.1rem !important;
    height: 2.6rem;
    position: absolute;
    right: 0;
    width: 2.6rem;
}
#panel > .body > .links > .link-combo.expanded > .dom > .controller { transform: rotateZ(270deg); }
#panel > .body > .links > .link-combo > .subs { display: none; }
#panel > .body > .links > .link-combo.expanded > .subs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1.25rem;
}
#panel > .body > .links > .link-combo.expanded > .subs > * { padding: 0.75rem 1.5rem; }



#user-conrol {
    align-items: center;
    display: flex;
    gap: 2.5rem;
    justify-content: flex-end;
}
#user-conrol > .icon {
    height: 1.5rem;
    position: relative;
    width: 1.5rem;
}
#user-conrol > .icon::after {
    align-items: center;
    background: #701C15;
    border-radius: 1rem;
    color: #FFFFFF;
    content: attr(data-badge);
    display: flex;
    font-size: 0.7rem;
    height: 1rem;
    justify-content: center;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    width: 1rem;
}
#user-conrol > #modal.notifications {
    background: #FFFFFF;
    border-left: 0.0625rem solid #F0F1F3;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 25rem;
    z-index: 10;
}
#user-conrol > #modal.notifications > .title,
#user-conrol > #modal.notifications > .controls { padding: 1.5rem 1rem; }
#user-conrol > #modal.notifications > .title {
    align-items: center;
    border-bottom: 0.0625rem solid #DFDCD8;
    color: #1A1918;
    display: flex;
    justify-content: space-between;
}
#user-conrol > #modal.notifications > .controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
#user-conrol > #modal.notifications > .controls > * {
    background: #9B64001A;
    padding: 0.5rem 0.75rem;
}
#user-conrol > #modal.notifications .list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}




#page-id {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
#page-id > .name {
    color: #721109;
    font-size: 2rem;
    font-weight: 650;
}
#page-id > .extras,
#page-id > .extras > * {
    align-items: center;
    display: flex;
}
#page-id > .extras {
    gap: 2.5rem;
    justify-content: space-between;
}
#page-id > .extras > .ctas { gap: 1rem; }
#page-id .cta {
    align-items: center;
    border-radius: 0.5rem;
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
}
#page-id .cta.export {
    background: #F0F1F3;
    color: #721109;
}
#page-id .cta.add {
    background: #721109;
    color: #FFFFFF;
}

#page-id > .extras > .breadcrumb { gap: 3rem; }
#page-id > .extras > .breadcrumb > * { position: relative; }
#page-id > .extras > .breadcrumb > :first-child { color: #3250FF; }
#page-id > .extras > .breadcrumb > :not(:last-child)::after {
    background: url('../images/breadcrumb_gap.svg');
    background-size: contain;
    background-position: center;
    content: '';
    height: 100%;
    left: calc(100% + 1rem);
    position: absolute;
    top: 0;
    width: 1rem;
}


/* landscape orientations */
@media screen and (orientation: landscape) {
    /* panel */
    #panel > .head > .brand { flex-grow: 1; }
    #panel > .head > .menu { display: none; }
}


/* small width landscape */
@media screen and (orientation: landscape) and (max-width: 1081px) {
    #panel { gap: 2rem; }

    #panel > .head > .back img { height: 1em; }
}



/* portrait orientations */
@media screen and (orientation: portrait) {
    /* panel */
    #panel > .head { z-index: var(--LAYER-NAV-HEAD); }

    #panel > .body { display: none; }
    #panel.expanded > .body {
        background: var(--THEME-NAV-BACKGROUND);
        display: flex;
        height: 100%;
        left: 0;
        padding: 5rem 1rem 1rem 1rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: var(--LAYER-NAV-BODY);
    }
}