.layout.auth,
.layout.default {
    background: var(--theme-background);
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
}


/* auth */
.layout.auth {
    align-items: center;
    justify-content: center;
}
.layout.auth > #content {
    border: 0.0625rem solid #8F959E52;
    border-radius: 0.5rem;
    padding: 2.5rem;
    width: 25rem;
}

.layout.default { display: inline-block; }
.layout.default > * {
    height: 100%;
    float: left;
}

.layout.default > #navigation {
    position: sticky;
    top: 0;
    width: var(--NAVIGATION-PANEL-WIDTH);
}

.layout.default > #content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem 0 2rem;
    width: calc(100% - var(--NAVIGATION-PANEL-WIDTH));
}
.layout.default > #content > :last-child { padding-bottom: 2rem; }




/* prevent small screen access */
@media screen and (max-width: 1079px) {
    .layout { position: relative; }
    .layout::after {
        align-items: center;
        background: #FFFFFF;
        box-sizing: border-box;
        content: "Screen is too small, A minimum of a tablet sized screen (1080px) is required";
        display: flex;
        font-size: 2rem;
        height: 100%;
        justify-content: center;
        left: 0;
        padding: 2.5rem;
        position: absolute;
        text-align: center;
        top: 0;
        white-space: wrap;
        width: 100%;
        z-index: 999;
    }
}