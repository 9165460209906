[ data-page-name = "sign_in" ] section {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

[ data-page-name = "sign_in" ] .greeting {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}
[ data-page-name = "sign_in" ] .greeting > .main {
    font-size: 1.8rem;
    font-weight: 750;
}
[ data-page-name = "sign_in" ] .greeting > .sub { font-size: 0.9rem; }

[ data-page-name = "sign_in" ] form { width: 100%; }

[ data-page-name = "sign_in" ] form .button {
    background: var(--cta-primary);
    border-radius: 0.35rem;
    color: #FFFFFF;
    justify-content: center;
    width: 100%;
}