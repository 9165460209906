button {
    background: #721109;
    border-radius: 0.5rem;
    color: #FFFFFF;
    width: fit-content;
}

form { width: 42.5%; }
form .label {
    color: #777980;
    font-weight: 550;
}
form .keyarea { border: 0.0625rem solid #E0E2E7 !important; }

.title {
    color: #721109;
    font-size: 1.5rem;
    font-weight: 550;
}