.card.benp > .others > .status {
    border-radius: 0.5rem;
    padding: 0.125rem 0.35rem;
}
.card.benp > .others > .status.ONGOING {
    background: #E8F7EE;
    color: #16AD4D;
}
.card.benp > .others > .status.NOT_STARTED {
    background: #FFF1E7;
    color: #F77307;
    ;
}