[ data-page-name = "dashboard" ] #content {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

[ data-page-name = "dashboard" ] .briefing,
[ data-page-name = "dashboard" ] .charts {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

[ data-page-name = "dashboard" ] .briefing {
    flex-wrap: wrap;
    row-gap: 2.5rem;
}