form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
button, input {
    border: none;
    outline: none;
}

.input-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.input-field > * {
    align-items: center;
    display: flex;
}
.input-field > :nth-child(odd) { justify-content: space-between; }

.input-field .help {
    position: relative;
    width: fit-content;
}
.input-field .help > :not(.icon) {
    background: white;
    display: none;
    font-size: 0.75rem;
    position: absolute;
    right: 0;
    top: 100%;
    width: 10rem;
}
.input-field .help:hover > :not(.icon) { display: initial; }

button,
.input-field .keyarea { padding: 0.75rem !important; }

button {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 1rem;
}

.input-field .keyarea {
    align-items: center;
    border: 0.0625rem solid rgba(114, 17, 9, 0.4);
    border-radius: 0.35rem;
    display: flex;
    gap: 1rem;
}
.input-field.checkbox .keyarea,
.input-field.radio .keyarea { display: none; }
.input-field .keyarea > input {
    border: none;
    flex-grow: 1;
}

.input-field .label,
.input-field .extraLabel { font-size: 0.9rem; }

.input-field .label { color: var(--text-default); }
.input-field .extraLabel { color: var(--text-primary); }


.input-field .options {
    align-items: center;
    display: flex;
    gap: 1rem;
}
.input-field .options > * {
    background: rgb(218, 218, 218);
    border-radius: 0.35rem;
    padding: 0.5rem 1rem;
}
.input-field .options > .selected {
    background: #721109;
    color: #FFFFFF;
}